<template>
  <div class="about p-5 overflow-hidden container-fluid" id="dementie-information">
    <p v-if="errorMessage" v-bind:class="{'error': errorMessage}" v-bind:style="{ color: 'red' }">{{ errorMessage }}</p>
    <!-- Only display the data if the errorMessage property is not set -->
    <!-- <div v-if="!errorMessage"> -->
    

    <div v-if="questions">

      <h1 v-if="index===0" class="title">Netwerk in kaart</h1>

    <div v-if="index===0">
      <div class="row">
        <div class="col paragraphs">
          <p>Beste netwerkcoördinator, </p>
            <p>Graag vragen we je medewerking bij het invullen van onderstaande vragenlijst over de samenstelling en organisatie van je netwerk. De antwoorden worden geaggregeerd weergegeven in het overzicht ‘Netwerken in Kaart resultaten’ (uitklapmenu onder de tab ‘Zelfscan ZSD’) en zijn niet te herleiden naar een netwerk.</p>
            <p>De resultaten geven een goed inzicht in de netwerken in Nederland en het kan jou helpen bij de vormgeving van je eigen netwerk. Voor DNN en voor andere organisaties kunnen de gegevens behulpzaam zijn in gesprekken over beleid en om de juiste ondersteuning aan netwerken te geven.</p>
            <p>Veel dank!</p>

        </div>

        <hr style="border: 2px solid black;">


      </div>

      <!-- Vraag over preferente zorgverzekeraar -->
      <div class="select-option6 d-flex justify-content-between align-items-center">
          <label style="font-size: 1.2em; font-weight: bold; margin: 30px 0;"
          class='col-sm-6 label-text'
          for="selectPositie">{{filteredQuestions[36].vraag.split('.')[1]}}</label>
          <b-form-select 
          aria-label="Selecteer aantal uur per week" autocomplete="on"
          id="selectPositie"
          v-model="filteredQuestions[36].antwoord"
          :options="options2"
          size="lg" 
          class="col-sm-6 custom-select-form"
          required></b-form-select>
        </div>

        <div class="d-flex justify-content-end">
        <button @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabledNext1">Volgende pagina</button>
        </div>
    </div>
  </div>


    <div v-if="questions" class="form form-space">

      <div v-if="index===1 && this.vragengroep_id == 1">

        <h5>Vul in per gemeente dat binnen het netwerk valt in welke mate deze is aangesloten bij het netwerk:</h5>
        
       

        <div>
          <div class="mb-3 row"></div>

          <!-- Begin Vraag 1 -->
          <div class="align-items-center justify-content-between table-header">
            <div class="p-2" style="width: 290px;"></div>
              <div class="row">
                  <div class="col-sm-3">
                    <p></p>
                  </div>
                  <div class="col-sm-3">
                    <p>Is deze gemeente een samenwerkingspartner in het netwerk?</p>
                  </div>
                  <div class="col-sm-3">
                    <p>Zijn met de gemeente formele samenwerkingsafspraken gemaakt?</p>
                  </div>
                  <div class="col-sm-3">
                    <p>Is de gemeente aangesloten bij stuurgroep of regionaal bestuur?</p>
                  </div>
              </div>
          </div>

          <template v-for="gemeente in getallGemeentes">
            <div class="align-items-center justify-content-center" :key="`ques-${gemeente.gemeentenaam}`">
            <div class="row">
              <div class="col-sm-3">
                <div class="p-2">
                  <div class="question-group" 
                  style="width: 290px; margin-right: 5px;">
                    {{ gemeente.gemeentenaam }}
                  </div>
                </div>

              </div>

              <div class="col-sm-3 label-flex-container">
                <div class="options-container options-radio-button">
                    <template v-for="(item, idx) in options4_5">
                      <div class="d-inline-flex justify-content-around options-questions" :key="`radio-${idx}`"  style="margin-left: 20px">
                        <label tabindex="0" class="button-label nee-ja-button" :class="{ 'radio-selected': gemeente.antwoord3 == item.value }">
                          {{ item.text }}
                          <input class="hidden radio-label" type="radio" :name="`question-${index}`" 
                          v-model="gemeente.antwoord3" :value="item.value" />
                        </label>
                      </div>
                    </template>
                  </div>
                </div>

              <div class="col-sm-3 label-flex-container">
                <div class="options-container options-radio-button">
                    <template v-for="(item, idx) in options4_5">
                      <div class="d-inline-flex justify-content-around options-questions" :key="`radio-${idx}`"  style="margin-left: 20px">
                        <label tabindex="0" class="button-label nee-ja-button" :class="{ 'radio-selected': gemeente.antwoord4 == item.value }">
                          {{ item.text }}
                          <input class="hidden radio-label" type="radio" :name="`question-${index}`" 
                          v-model="gemeente.antwoord4" :value="item.value" />
                        </label>
                      </div>
                    </template>
                  </div>
                </div>

              <div class="col-sm-3 label-flex-container">
                <div class="options-container options-radio-button">
                    <template v-for="(item, idx) in options4_5">
                      <div class="d-inline-flex justify-content-around options-questions" :key="`radio-${idx}`"  style="margin-left: 20px">
                        <label tabindex="0" class="button-label nee-ja-button" :class="{ 'radio-selected': gemeente.antwoord5 == item.value }">
                          {{ item.text }}
                          <input class="hidden radio-label" type="radio" :name="`question-${index}`" 
                          v-model="gemeente.antwoord5" :value="item.value" 
                          />
                        </label>
                      </div>
                    </template>
                  </div>
                </div>
            </div>
          </div>
          </template>

          <div>
          <p class='col-sm-6' for="anders1"></p>
          <input class= "custom-input col-sm-12" placeholder="Ontbreekt er een gemeente of is een gemeente onterecht weergeven, vul hier in:" v-model="netwerkcoordinator.anders1"/>
        </div>


        <!-- EIND  VRAAG 1 -->



          <div class="mb-3 row"></div>

        <hr style="border: 2px solid black;">

          <div class="mb-3 row"></div>
          <div class="mb-3 row"></div>

          <h5> Vul in per (type) organisatie in welke mate deze is aangesloten bij het netwerk:</h5>

          <div class="mb-3 row"></div>

          <!-- VRAAG 3,4,5 -->
          <!-- This is the main header of Q3,4,5 -->
          <div class="align-items-center justify-content-between table-header">
            <div class="p-2" style="width: 290px;"></div>
              <div class="row">
                  <div class="col-sm-3">
                    <p></p>
                  </div>
                  <div class="col-sm-3">
                    <p>Is de organisatie een samenwerkingspartner in het netwerk?</p>
                    <p style="font-weight: normal; font-style: italic;">1=Geen, 2=Enkelen, 3=De helft, 4=De meesten, 5=Allen</p>
                  </div>
                  <div class="col-sm-3">
                    <p>Zijn met de organisatie formele samenwerkingsafspraken gemaakt?</p>
                  </div>
                  <div class="col-sm-3">
                    <p>Is de organisatie aangesloten bij stuurgroep of regionaal bestuur?</p>
                  </div>
              </div>
          </div>

        <!-- Iterate over the 12 question -->
        <template>
          <div v-for="index in index_array" :key="index">

          <!-- This is the extra header for the four last questions -->
          <div v-if="index===24" class="align-items-center justify-content-between table-header" >
            <hr style="border: 2px solid black;">

            <!-- Old code, could be used instead of the <hr> line above -->
            <!-- <div class="p-2" style="width: 290px;"></div>
              <div class="row">
                  <div class="col-sm-3">
                    <p></p>
                  </div>
                  <div class="col-sm-3">
                    <p>Is de organisatie een samenwerkingspartner in het netwerk?</p>
                    
                  </div>
                  <div class="col-sm-3">
                    <p>Zijn met de organisatie formele samenwerkingsafspraken gemaakt?</p>
                  </div>
                  <div class="col-sm-3">
                    <p>Is de organisatie aangesloten bij stuurgroep of regionaal bestuur?</p>
                  </div>
              </div> -->
          </div>

          <!-- These are the organizations -->
          <div class="align-items-center justify-content-center" :key="`ques-${index}`">
            <div class="row">
              <div class="col-sm-3">
                <div class="p-1">
                  <div class="question-group" v-if="shouldDisplayHeader(index)" 
                  style="width: 290px; margin-right: 5px;">
                    {{ filteredQuestions2(index)[0].vragengroep }}
                  </div>
                </div>
              </div>

              <!-- This is the column of Q3 -->
              <div class="col-sm-3 label-flex-container">
                <div class="options-container options-radio-button">
                    <template v-for="(item, idx) in selectedOptionsQ3(filteredQuestions2(index)[0].vragengroep)">
                      <div class="d-inline-flex justify-content-around" :key="`radio-${idx}`"  style="margin-left: 20px">
                        <label tabindex="0" :class="{'button-label': item.text.length >= 2, 'button-label-small': item.text.length < 2, 'nee-ja-button': true, 'radio-selected': filteredQuestions2(index)[0].antwoord == item.value}" 
                        @keyup.enter="selectRadioButton(index, 0, item.value)">
                          {{ item.text }}
                          <input class="hidden radio-label" type="radio" :name="`question-${index}`" 
                          v-model="filteredQuestions2(index)[0].antwoord" :value="item.value" />
                        </label>
                      </div>
                    </template>
                  </div>
                </div>

                <!-- This is the column of Q4 -->
              <div class="col-sm-3 label-flex-container">
                <div class="options-container options-radio-button">
                    <template v-for="(item, idx) in options4_5">
                      <div class="d-inline-flex justify-content-around options-questions" :key="`radio-${idx}`"  style="margin-left: 20px">
                        <label tabindex="0" class="button-label nee-ja-button" :class="{ 'radio-selected': filteredQuestions2(index)[1].antwoord == item.value }" 
                        @keyup.enter="selectRadioButton(index, 1, item.value)">
                          {{ item.text }}
                          <input class="hidden radio-label" type="radio" :name="`question-${index}`" 
                          v-model="filteredQuestions2(index)[1].antwoord" :value="item.value" />
                        </label>
                      </div>
                    </template>
                  </div>
                </div>

                <!-- This is the column of Q5 -->
              <div class="col-sm-3 label-flex-container">
                <div class="options-container options-radio-button">
                    <template v-for="(item, idx) in options4_5">
                      <div class="d-inline-flex justify-content-around options-questions" :key="`radio-${idx}`"  style="margin-left: 20px">
                        <label tabindex="0" class="button-label nee-ja-button" :class="{ 'radio-selected': filteredQuestions2(index)[2].antwoord == item.value }" 
                        @keyup.enter="selectRadioButton(index, 2, item.value)">
                          {{ item.text }}
                          <input class="hidden radio-label" type="radio" :name="`question-${index}`" 
                          v-model="filteredQuestions2(index)[2].antwoord" :value="item.value" 
                          />
                        </label>
                      </div>
                    </template>
                  </div>
                </div>
            </div>
          </div>
          </div>
        </template>

        </div>
            
        <!--this needs to be checked, copied from quickscan for now-->
        <div class="d-flex align-items-center">
          <div class="d-flex justify-content-start flex-grow-1 bd-highlight">
            <button type="button" @click="previousPage" class="btn btn-primary lager" :disabled="false">Vorige pagina</button>
          </div>
          <div class="d-flex justify-content-end">
              <button type="button" @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabledNext">Volgende pagina</button>
              <!-- <button v-if="this.vragengroep_id == 11" type="submit" @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabled2">Verstuur</button> -->
          </div>
        </div>
      </div>



      <div v-if="index===2">
        <h2>Personeel & Capaciteit</h2>
        <div class="mb-3 row"></div>
        <!-- VRAAG 6 -->
        <div class="select-options6 d-flex justify-content-between align-items-center" >
          <label style="font-size: 1.2em; font-weight: bold; margin: 30px 0;"
          class='col-sm-6 label-text' 
          for="selectPositie">{{filteredQuestions[0].vraag}}</label>
          <b-form-select 
          aria-label="Selecteer aantal uur per week" autocomplete="on"
          id="selectPositie"
          v-model="filteredQuestions[0].antwoord" 
          :options="options6_7"
          size="lg" 
          class="col-sm-6 custom-select-form" 
          required></b-form-select>
      </div>
      <div class="mb-3 row"></div>

      <div>
        <label style="font-size: 1.2em; font-weight: bold; margin: 30px 0;"
          class='col-sm-6 label-text ' >{{ filteredQuestions[1].vraag.split('-')[0] }}</label>
      </div>


      <!-- VRAAG 7 -->
      <div class="d-flex justify-content-between align-items-center">
          <label style="margin-left: 30px"
          for="selectPositie">{{ filteredQuestions[1].vraag.split('-')[1] }}</label>
          <b-form-select 
          aria-label="Selecteer aantal uur per week" autocomplete="on"
          id="selectPositie" 
          v-model="filteredQuestions[1].antwoord"
          :options="options6_7"
          size="lg" 
          class="col-sm-6 custom-select-form" 
          required></b-form-select>

      </div>

      <div class="d-flex justify-content-between align-items-center">
          <label 
          style="margin-left: 30px" 
          for="selectPositie">{{ filteredQuestions[2].vraag.split('-')[1] }}</label>
          <b-form-select 
          aria-label="Selecteer aantal uur per week" autocomplete="on"
          id="selectPositie" 
          v-model="filteredQuestions[2].antwoord"
          :options="options6_7"
          size="lg" 
          class="col-sm-6 custom-select-form" 
          required></b-form-select>

      </div>

      <div class=" d-flex justify-content-between align-items-center">
          <label 
          style="margin-left: 30px"
          for="selectPositie">{{ filteredQuestions[3].vraag.split('-')[1] }}</label>
          <b-form-select 
          aria-label="Selecteer aantal uur per week" autocomplete="on"
          id="selectPositie" 
          v-model="filteredQuestions[3].antwoord"
          :options="options6_7"
          size="lg" 
          class="col-sm-6 custom-select-form" 
          required></b-form-select>

      </div>

      <div class=" d-flex justify-content-between align-items-center">
          <label 
          style="margin-left: 30px"
          for="selectPositie">{{ filteredQuestions[4].vraag.split('-')[1] }}</label>
          <b-form-select 
          aria-label="Selecteer aantal uur per week" autocomplete="on"
          id="selectPositie" 
          v-model="filteredQuestions[4].antwoord"
          :options="options6_7"
          size="lg" 
          class="col-sm-6 custom-select-form" 
          required></b-form-select>

      </div>

      <div class="select-intervallen_vr7 d-flex justify-content-between align-items-center">
          <label 
          style="margin-left: 30px"
          for="selectPositie">{{ filteredQuestions[5].vraag.split('-')[1] }}</label>
          <b-form-select 
          aria-label="Selecteer aantal uur per week" autocomplete="on"
          id="selectPositie" 
          v-model="filteredQuestions[5].antwoord"
          :options="options6_7"
          size="lg" 
          class="col-sm-6 custom-select-form" 
          required></b-form-select>

      </div>

      <div class="mb-3 row"></div>
      <div class="mb-3 row"></div>

      <!-- VRAAG 8 -->
      <div class="d-flex justify-content-between align-items-center">
      <label style="font-size: 1.2em; font-weight: bold; margin: 30px 0;"
          class='col-sm-6 label-text' >{{ filteredQuestions[6].vraag }} (Vul heel aantal uren in)</label>
        <div class="col-sm-6">
        <input
          type="number"
          id="inputPositie"
          v-model="filteredQuestions[6].antwoord"
          @input="handleInputInteger"
          class="form-control"
          required
        />
        </div>
    </div>


      <div class="d-flex align-items-center">
      <div class="d-flex justify-content-start flex-grow-1 bd-highlight">
        <button @click="HandlePreviousButton" class="btn btn-primary lager">Vorige pagina</button>
        </div>
      <div class="d-flex justify-content-end">
        <button @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabledNext">Volgende pagina</button>
      </div>
    </div>

      </div>

    
      <div v-if="index===3">
        <h2>Samenwerking</h2>

     <div class="select-relatie d-flex justify-content-between align-items-center">
        <label style="font-size: 1.2em; font-weight: bold; margin: 10px 0;"
          class='col-sm-6 style="margin-left: 30px"' for="checkboxRelatie">{{ filteredQuestions[0].vraag }}</label>

      </div>
      <div style="padding-left: 50px; font-weight: normal;">
            <b-form-checkbox-group class="col-sm-6 custom-checkbox-group"
            v-model="filteredQuestions[0].antwoord"
              :options="options9"
            name="flavour-1a"
            
            ></b-form-checkbox-group>
      </div>
      
      <div v-if="filteredQuestions[0].antwoord" >
        <div style="padding-left: 50px;" v-if="filteredQuestions[0].antwoord.includes(5)">
          <p class='col-sm-6' for="anders9"></p>
          <input class= "custom-input col-sm-6" placeholder="Namelijk" v-model="netwerkcoordinator.anders9"/>
        </div>
      </div>

      <div class="mb-3 row"></div>
      <div class="mb-3 row"></div>

        <!-- Question 10 -->
        <div class="d-flex justify-content-between align-items-center">
        <label style="font-size: 1.2em;margin: 10px 0; font-weight: bold;"
          class='col-sm-6 label-text'  for="checkboxOverleggen">{{ filteredQuestions[1].vraag }}</label>

      </div>
      <div style="padding-left: 50px;">
            <b-form-checkbox-group
              v-model="filteredQuestions[1].antwoord"
              :options="options10"
            name="flavour-1a"
            class="col-sm-6"
            ></b-form-checkbox-group>
      </div>

      <div v-if="filteredQuestions[1].antwoord" >
        <div style="padding-left: 50px;" v-if="filteredQuestions[1].antwoord.includes(4)">
          <p class='col-sm-6' for="anders9"></p>
          <input class= "custom-input col-sm-6" placeholder="Namelijk" v-model="netwerkcoordinator.anders10"/>
        </div>
      </div>

      <div class="mb-3 row"></div>
      <div class="mb-3 row"></div>


              <!-- Question 11 -->
        <div class="d-flex justify-content-between align-items-center">
        <p style="font-size: 1.2em; font-weight: bold;margin: 10px 0;"
          class='col-sm-6 label-text'  for="checkboxOverleggen">{{ filteredQuestions[2].vraag }}</p>

      </div>
      <div style="padding-left: 50px;">
            <b-form-checkbox-group
              v-model="filteredQuestions[2].antwoord"
              :options="options11"
            name="flavour-1a"
            class="col-sm-6"
            ></b-form-checkbox-group>
      </div>
      <div v-if="filteredQuestions[2].antwoord" >
        <div style="padding-left: 50px;" v-if="filteredQuestions[2].antwoord.includes(5)">
          <p class='col-sm-6' for="anders9"></p>
          <input class= "custom-input col-sm-6" placeholder="Namelijk" v-model="netwerkcoordinator.anders11"/>
        </div>
      </div>

      <div class="mb-3 row"></div>
      <div class="mb-3 row"></div>


          <!-- Question 12-->
      <div class="d-flex justify-content-between align-items-center">
        <label style="font-size: 1.2em; font-weight: bold; margin: 10px 0;"
          class='col-sm-6 label-text'  for="checkboxOverleggen">{{ filteredQuestions[3].vraag }}</label>
      </div>
      <div style="padding-left: 50px;">
            <b-form-checkbox-group
              v-model="filteredQuestions[3].antwoord"
              :options="options12"
            name="flavour-1a"
            class="col-sm-6"
            ></b-form-checkbox-group>
      </div>
      <div v-if="filteredQuestions[3].antwoord" >
        <div style="padding-left: 50px;" v-if="filteredQuestions[3].antwoord.includes(5)">
          <p class='col-sm-6' for="anders9"></p>
          <input class= "custom-input col-sm-6" placeholder="Namelijk" v-model="netwerkcoordinator.anders12"/>
        </div>
      </div>

      <div class="mb-3 row"></div>
      <div class="mb-3 row"></div>

        <!-- Question 13 -->
        <div class="d-flex justify-content-between align-items-center">
        <label style="font-size: 1.2em; font-weight: bold; margin: 10px 0;"
          class='col-sm-6 label-text'  for="checkboxOverleggen">{{ filteredQuestions[4].vraag }}</label>
      </div>
      <div style="padding-left: 50px;">
            <b-form-checkbox-group
              v-model="filteredQuestions[4].antwoord"
              :options="options13"
            name="flavour-1a"
            class="col-sm-6"
            ></b-form-checkbox-group>
      </div>
      <div v-if="filteredQuestions[4].antwoord" >
        <div style="padding-left: 50px;" v-if="filteredQuestions[4].antwoord.includes(6)">
          <p class='col-sm-6' for="anders9"></p>
          <input class= "custom-input col-sm-6" placeholder="Namelijk" v-model="netwerkcoordinator.anders13"/>
        </div>
      </div>

      <div class="mb-3 row"></div>

      <div class="d-flex aligh-items-center">
      <div class="d-flex justify-content-start flex-grow-1 bd-highlight">
        <button @click="HandlePreviousButton" class="btn btn-primary lager">Vorige pagina</button>
        </div>
      <div class="d-flex justify-content-end">
        <button @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabledSend">Verstuur</button>
      </div>
    </div>

      </div>

      <div v-if="index===4">
          <div v-if="saved" class="alert alert-success custom-alert" role="alert">
            Antwoorden opgeslagen, bedankt!
          </div>
          <button class="btn btn-secondary" @click="backToMenu" autofocus>Terug naar menu</button>
        </div>
      
        </div>

      </div>
    </template>

<script>

import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      questions: null,
      index_array: [0,3,6,9,12,15,18,21,24,27,30,33],
      index: 0,
      vragengroep_id:1,
      allGemeentes: [],
      netwerkcoordinator: {
          dementienetwerk: '',
          user_id: '',
          antwoorden1: [],
          antwoorden: [],
          anders1: '',
          anders9: '',
          anders10: '',
          anders11: '',
          anders12: '',
          anders13: '',

      },
      // Opties voor vraag 2
      options2: [
            { text: 'Zilveren Kruis', value: 1 },
            { text: 'ASR', value: 2 },
            { text: 'CZ', value: 3 },
            { text: 'DSW', value: 4 },
            { text: 'Salland', value: 5 },
            { text: 'Menzis', value: 6 },
            { text: 'ONVZ', value: 7 },
            { text: 'VGZ', value: 8 },
            { text: 'Zorg en Zekerheid', value: 9 },
            { text: 'Caresq', value: 10 }
          ],
        options3: [
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 },
          { text: '5', value: 5 }
        ],
      options4_5: [
          { text: 'Nee', value: 1 },
          { text: 'Ja', value: 2 },
        ],
      options6_7: [
            { text: '0 uur', value: 1 },
            { text: '1-4 uur', value: 2 },
            { text: '5-8 uur', value: 3 },
            { text: '9-12 uur', value: 4 },
            { text: '13-16 uur', value: 5 },
            { text: '17-20 uur', value: 6 },
            { text: '21-24 uur', value: 7 },
            { text: '25-32 uur', value: 8 },
            { text: '>32 uur', value: 9 }
          ],

      errorMessage: '',
      fte_vr8: 0,
      
       options9: [
                  { text: 'Hiërarchisch leidinggevende', value: 1 },
                  { text: 'Functioneel leidinggevende', value: 2 },
                  { text: 'Hiërarchisch en functioneel leidinggevende', value: 3 },
                  { text: 'Samenwerkingsrelatie', value: 4 },
                  { text: 'Anders', value: 5 }
                ],

      options10: [
                  { text: 'Tussen gemeenten, zorgverzekeraars en zorgkantoren', value: 1 },
                  { text: 'In het kader van IZA/ WOZO/ GALA', value: 2 },
                  { text: 'Nee, niet betrokken', value: 3 },
                  { text: 'Anders', value: 4 }
                ],

      options11: [
                { text: 'Palliatieve netwerk', value: 1 },
                { text: 'CVA- en/of NAH netwerk', value: 2 },
                { text: 'Netwerk kwetsbare ouderen', value: 3 },
                { text: 'Nee, geen samenwerking', value: 4 },
                { text: 'Anders', value: 5 }
              ],

      options12: [
                { text: 'Jonge mensen met dementie', value: 1 },
                { text: 'Mensen met een migratie-achtergrond', value: 2 },
                { text: 'Mensen met een verstandelijke beperking', value: 3 },
                { text: 'Nee, er zijn geen afspraken', value: 4 },
                { text: 'Anders', value: 5 }
              ],

      options13: [
                  { text: 'Zorgverzekeraar', value: 1 },
                  { text: 'Gemeente', value: 2 },
                  { text: 'Zorgkantoor', value: 3 },
                  { text: 'Deelnemende organisaties', value: 4 },
                  { text: 'Niemand', value: 5 },
                  { text: 'Anders', value: 6 }
                ],
      
    };
  },
  computed: {
    ...mapGetters({userInfo: 'userInfo' }),

  filteredQuestions() {
      const uniqueGroups = [...new Set(this.questions.map(question => question.vragengroep))];
      const filteredQuestions = this.questions.filter((question) => {
        this.vragengroep = question.vragengroep;
        if (this.vragengroep_id === 1) {
          return question.vragengroep_id === 1;
        } 
        else if (this.vragengroep_id === 2) {
          return question.vragengroep_id === 2;
        }
        else if (this.vragengroep_id === 3) {
          return question.vragengroep_id === 3;
        }
        else {
          return question.vragengroep === uniqueGroups[this.vragengroep_id - 2];
        }
      });
      return filteredQuestions;
    },

    getallGemeentes() {
      return this.allGemeentes;
    },

    // For intro page
    isDisabledNext1() {
        if (this.filteredQuestions[36].antwoord === null || this.filteredQuestions[36].antwoord === undefined) {
          return true;
        }
        else {
          return false
        }
    },
    
    // For second and third page
    isDisabledNext(){
        if (this.filteredQuestions.some(obj => obj.antwoord === undefined || obj.antwoord === null)) {
          return true;
        }
        else{
          return false;
         }
      },
  
    // For sending answers
    isDisabledSend() {

      if (this.filteredQuestions.some(obj => obj.antwoord === undefined || obj.antwoord === null || obj.antwoord.length === 0) || 
      (this.filteredQuestions[0].antwoord.includes(5) && this.netwerkcoordinator.anders9 == '') ||
      (this.filteredQuestions[1].antwoord.includes(4) && this.netwerkcoordinator.anders10 == '') ||
      (this.filteredQuestions[2].antwoord.includes(5) && this.netwerkcoordinator.anders11 == '') ||
      (this.filteredQuestions[3].antwoord.includes(5) && this.netwerkcoordinator.anders12 == '') ||
      (this.filteredQuestions[4].antwoord.includes(6) && this.netwerkcoordinator.anders13 == '')) {
        return true;
      }
      else{
        return false
      }
      },


  },
  mounted () {
      this.loadData() // To load Gemeentes for Q1
      this.$http.get('/api/vragen_netwerkinkaart/').then((response) => {
        response.data.sort((a,b) => a.id-b.id)
        this.questions = response.data.map((question) => {
        const koppeltabelnetwerkinkaart = question.koppeltabelnetwerkinkaart[0] || {}
        return {
          id: question.id,
          vraag: question.vraag,
          vraag_id: koppeltabelnetwerkinkaart.vraag_id,
          vragengroep_id: koppeltabelnetwerkinkaart.vragengroep_id,
          vragengroep: koppeltabelnetwerkinkaart.vragengroep,
        }
      })
    }).catch((error) => {
      console.error(error)
      if(error.response.status == 500){
            this.errorMessage = 'Er zijn nog geen quickscan vragen bekend. Zodra deze worden aangeleverd, zullen ze hier worden toegevoegd.' 
          }
          else if(error.response.status == 401){
            this.errorMessage = 'U hebt geen toegang tot deze pagina.' 
          }
          else {
            this.errorMessage = 'Onbekende foutmelding. Contacteer alstublieft dementiedata@amsterdamdatacollective.com.'
          }
    })
  },
  
       

  methods: {
      selectRadioButton(questionIndex, optionIndex, selectedValue) {
        // the question object based on the questionIndex
        const question = this.filteredQuestions2(questionIndex)[optionIndex];

        // the selected option for the question
        question.antwoord = selectedValue;
      },
      selectSecondRadioButtons(question, selectedValue) {
        question.antwoord = selectedValue;
      },
      filteredQuestions2(index) {
        return this.filteredQuestions.slice(index,index+3)
      },
      previousPage() {
        if (this.index == 3) {
          this.vragengroep_id = 1;
        }
        this.index = this.index - 1
      },
      shouldDisplayHeader(index) {
      // checking if the current question has the same vragengroep value as the previous question
      if (index === 0 || this.filteredQuestions[index].vragengroep !== this.filteredQuestions[index - 1].vragengroep) {
        return true;
      } else {
        return false;
      }
    },
    shouldDisplayQuestion(index) {
      // checking if the current question is the three questions of the first vragengroep
      if (index === 0 || index === 1 || index === 2) {
        return true;
      }

      return false;
    },
      nextQuestionGroup(){
        // assigning dementienetwerk and user id to the data
        this.netwerkcoordinator.dementienetwerk = this.userInfo.dementienetwerk
        this.netwerkcoordinator.user_id = this.userInfo.id
        this.netwerkcoordinator.antwoorden=this.filteredQuestions
        this.netwerkcoordinator.antwoorden1=this.getallGemeentes

        // sending the API to save answers when clicked on button Next in each page
        if (this.index === 1) {
          const requestBody = {
          netwerkcoordinator : this.netwerkcoordinator,
          submit_at_next1 : true // parameter to send backend for submitting on the next button
        }
          this.$http.post('/api/netwerkcoordinatorsnetwerkinkaart/', requestBody)

          if (this.vragengroep_id == 1) {
            this.index = this.index + 1
            this.vragengroep_id = this.vragengroep_id + 1
          }
          else{
            this.vragengroep_id = this.vragengroep_id + 1
          }
        }
        // For first page
        else if (this.index === 0) {
          this.index = this.index + 1
        }

        else if (this.index === 2) {
          const requestBody = {
          netwerkcoordinator : this.netwerkcoordinator,
          submit_at_next2 : true // parameter to send backend for submitting on the next button
        }
        this.$http.post('/api/netwerkcoordinatorsnetwerkinkaart/', requestBody)
        this.index = this.index + 1
        this.vragengroep_id = this.vragengroep_id + 1
      }

      else if (this.index === 3) {
        const requestBody = { 
          netwerkcoordinator : this.netwerkcoordinator,
          // To submit all ints of answers
          submit_at_next2: true,
          // To submit all 'anders' answers
          submit_at_next3: true
        }
        this.$http.post('/api/netwerkcoordinatorsnetwerkinkaart/', requestBody)
        this.index = this.index + 1
        this.saved = true
        // this.vragengroep_id = this.vragengroep_id + 1
      }

      },

      PrevQuestionGroup(){
        if (this.vragengroep_id === 0) {
          this.vragengroep_id = 0
        }
        else{
          this.vragengroep_id = this.vragengroep_id - 1
        }
      },
      vorige() {
        if (this.index>1) {
          this.index = this.index - 1
        }
      },
      HandlePreviousButton(){
        this.PrevQuestionGroup();
        this.vorige();
      },

      selectedOptionsQ3(vragengroep) {
      // Replace 'X' with the actual condition you want to check
      if (!['Regionale afdeling Alzheimer Nederland', 'Ziekenhuis/geheugenpoli', 'Zorgverzekeraar', 'Zorgkantoor'].includes(vragengroep)) {
        return this.options3;
      } else {
        return this.options4_5;
      }
    },

    // To handle potential float numbers of Q8
    handleInputInteger(event) {
      // Ensure that the input value is an integer
      const inputValue = parseInt(event.target.value);

      // Update the model with the sanitized integer value
      this.$set(this.filteredQuestions[6], 'antwoord', isNaN(inputValue) ? null : inputValue);
    },

      backToMenu() {
        this.$router.push('/zorgstandaard/Netwerkcoordinator/')
      
      },
      loadData() {
       this.$http.get(`/api/netwerkcoordinatorsnetwerkinkaart/get_gemeenten_by_netwerkid?netwerkId=${this.userInfo.dementienetwerk}`).then(
        (response) => {
          this.allGemeentes = response.data
        },
        (error) => {
          console.log(error);
        }
      )},
    }

  
}
</script>

<style scoped>

.small-checkbox .form-check-input {
  width: 16px; /* Adjust the width as needed */
  height: 16px; /* Adjust the height as needed */
  margin-right: 5px; /* Adjust the margin as needed */
}

.col-sm-1 {
text-align: center;
}
.col-sm-6 {
text-align: left;
}
.mb-3{
text-align: left;
}
.col-sm-3 {
  text-align: center;
}
.col-md-3 {
  margin-left: 5px;
}
.col-sm-2 {
  text-align: center;
}
.lager {
  margin-top: 30px;
  font-weight: bold;
}

.btn {
  float: right;
  background-color: var(--color-1);
  border-color: var(--color-2);
  margin-bottom: 20px;
  transition: all .2s;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
  background: var(--color-14);
  color: var(--color-2);
  transform: translateY(-3px);
}

.btn:hover{
  outline: none !important;
  box-shadow: none !important;
  background: var(--color-14);
  color: var(--color-2);
  transform: translateY(-3px);
}

.paragraphs{
  background-color: var(--color-2) !important;
}

.label-text{
  color: var(--color-1);
  font-weight: bold;
}

.custom-link {
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
}

.form-space{
  margin-top: 2rem;
}

.custom-select-form{
  font-size: 16px;
  font-family: inherit;
  font-weight: normal;
  background: rgba(57, 57, 57, 0.07);
  margin: 12.5px 0;
  height: 40px;
  border: none;
  padding: 0 30px;
  border-radius: 10px;
}

.custom-select-form{
  font-size: 16px;
  font-family: inherit;
  font-weight: normal;
  background: rgba(57, 57, 57, 0.07);
  margin: 12.5px 0;
  height: 40px;
  border: none;
  padding: 0 30px;
  border-radius: 10px;
}

.custom-select-form:focus{
  outline: none !important;
  border: 3px solid var(--color-1);
  background-color: rgba(57, 57, 57, 0.15);
}

.custom-select-form:hover{
  background-color: rgba(57, 57, 57, 0.15);
}

.link{
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
  color: var(--color-1);
  font-weight: bold;
}

.label-flex-container {
  display: flex;
  align-items: center; /* This will vertically center the text */
  justify-content: center; /* This will horizontally center the text */
}

.link:visited {
  color: var(--color-7);
}

.link:hover {
  background-color: rgba(57, 57, 57, 0.15);
  border-radius: .5em;
}

.link:focus {
  outline: none !important;
  border-radius: .5em;
  border: 3px solid var(--color-1);
}

.title{
  margin-bottom: 2rem;
}

.rb-box {
  width: 80%;
  max-width: 420px;
  margin: 50px auto;
  padding: 1.3em;
  background: var(--color-1);
  border-radius: .75em;
  -webkit-filter: drop-shadow(1px 2px 5px rgba(0,0,0,.3));
  filter: drop-shadow(1px 2px 5px rgba(0,0,0,.3));
  box-shadow: 
    0 2px 2px rgba(243,49,128,.5),
    0 0px 5px rgba(243,49,128,.15),
    0 0px 4px rgba(0,0,0,.35),
    0 5px 20px rgba(243,49,128,.25),
    0 15px 50px rgba(243,49,128,.75),
    inset 0 0 15px rgba(255,255,255,.05);
}

/* Custom Radio Button */
.rb {
  padding: 16px 0;
  text-align: center;
  background: rgba(255,255,255,.03);
  border-radius: .3em;
}

.rb-tab {
  display: inline-block;
  position: relative;
  width: 20%;
  cursor: pointer;
}

.rb-tab-active .rb-spot {
  background: var(--color-1);
  textcolor: var(--color-2);
  border: 2px solid var(--color-1);
}

.rb-tab-active .rb-txt {
  color: var(--color-2);
}

.rb-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: .8em;
  font-weight: bold;
  color: var(--color-1);
}

.rb-txt-nvt {
  position: absolute;
  top: 210%;
  left: 50%;
  width: 110px;
  max-width: 110px;
  transform: translate(-50%, -50%);
  font-size: .8em;
  color: var(--color-1);
  text-align: center;
}

.rb-spot {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: 1.5px solid var(--color-1);
  border-radius: 100%;
  transition: border 0.15s ease, background-color 0.15s ease;
}

.rb-spot:hover,
.rb-spot:focus {
  outline: none;
  box-shadow: none;
  background-color: var(--color-14);
  color: var(--color-2) !important;
  border: 2px solid var(--color-14);
}

.rb-spot:hover .rb-txt,
.rb-spot:focus .rb-txt {
  color: var(--color-2);
}

.rb-input{
  visibility: hidden;
}

.p-text{
  padding: 1rem;
  font-size: 18px;
  font-weight: bold;
  color: var(--color-2);
}

.p-italic{
  font-style: italic;
  font-size: 16px;
}


.information-text{
  padding: 1rem;
  background-color: var(--color-1);
  margin: 2rem 0 2rem 0;
  border-radius: 30px;
}


.question-group{
  font-weight: bold;
  margin: 2rem 0 2rem 0;
}

.question{
  font-weight: bold;
  margin-top: 2rem;
}


.print-style{
  font-weight: bold;
  font-style: italic;
  color: var(--color-11);
}

.print-button{
  color: var(--color-1);
  font-size: 16px;
  cursor: pointer;
}

.custom-input{
  background: rgba(57, 57, 57, 0.07);
  border: none;
  border-radius: 10px;
}

.custom-input:focus{
  background-color: rgba(57, 57, 57, 0.15);
  border: 3px solid var(--color-1);
  border-radius: 10px;
}

.custom-input:hover{
  background-color: rgba(57, 57, 57, 0.15);
  border: none;
}

input {
  margin: 10px 0;
  height: 40px;
  padding: 0 30px;
}

input{
  outline: none;
}

input::placeholder{
  font-style: italic;
  color: var(--color-10);
}

.button-wrap {
  position: relative;
  text-align: center;
  top: 10%;
  margin-top: -2.5em;
  @media (max-width: $small) {margin-top: -1.5em;}
}

.button-label {
  text-align: center;
  font-size: 16px;
  width: 60px;
  display: inline-block;
  padding: 1em 1em;
  margin: 0.5em;
  cursor: pointer;
  color: $dark;
  border-radius: 0.25em;
  background: $light;
  box-shadow: 0 3px 10px rgba(0,0,0,0.2), inset 0 -3px 0 rgba(0,0,0,0.22);
  transition: 0.3s;

  @media (max-width: $small) {
    padding: 0em 1em 3px;
    margin: 0.25em;
  }
}

.button-label-small {
  text-align: center;
  font-size: 16px;
  width: 45px;
  display: inline-block;
  padding: 0.5em 0.5em;
  margin: 0.2em;
  cursor: pointer;
  color: $dark;
  border-radius: 0.25em;
  background: $light;
  box-shadow: 0 3px 10px rgba(0,0,0,0.2), inset 0 -3px 0 rgba(0,0,0,0.22);
  transition: 0.3s;

  @media (max-width: $small) {
    padding: 0em 1em 3px;
    margin: 0.25em;
  }
}


.button-label:focus {
  outline: none;
  box-shadow: none;
}

.nee-ja-button{
  color: var(--color-1);
  background: var(--color-2);
  font-weight: bold;
}

.nee-ja-button:hover{
  color: var(--color-2);
  background: var(--color-1);
}

.nee-ja-button:focus{
  color: var(--color-2);
  background: var(--color-14);
}



.radio-selected {
  background-color: var(--color-1);
  color: var(--color-2);
}


.hidden {
  display: none;
}

.table-header{
  color: var(--color-2);
  background: var(--color-1);
  font-weight: bold;
}

.table-sideheader{
  color: var(--color-1);
  font-weight: bold;
}

.options-radio-button:not(:first-child) {
  margin-left: 50px;
}

.options-questions:not(:first-child) {
  margin-right: 20px;
}


@media (max-width: 1250px) {
  .d-flex {
    flex-wrap: wrap;
  }

  .options-container {
    width: 100%;
  }
}


.btn-secondary {
  background: var(--color-1);
  font-weight: bold;
  height: 50px;
  padding-top: 5px !important;
  z-index: 12;
  margin-top: 30px;
  margin-right: 50px;
  width: 200px;
  max-width: 300px
}

.btn-secondary:focus {
  outline: none !important;
  box-shadow: none !important;
  background: #e8f0fe;
  color: var(--color-1);
  border:2px rgb(20,66,115) solid !important;
}

.btn-secondary:hover {
  outline: none !important;
  box-shadow: none;
  background: var(--color-4);
}

.custom-alert{
  font-weight: bold;
}
.custom-checkbox-group .custom-control-label {
  font-weight: normal;
}

</style>;
